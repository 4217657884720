<template>
  <div>
    <CRow>
      <CCol col="12" xl="12">
        <CCard>
          <CCardBody>
            <CRow>
              <CCol>
                
              </CCol>
            </CRow>
            <CDataTable
              hover
              :items="customers"
              :fields="fields"
              @row-clicked="getCustomerDetailsByID"
            >
              <template #created_at="data">
                <td>
                  <div>
                    {{ formatDate(data.item.created_at) }}
                  </div>
                </td>
              </template>
            </CDataTable>
            <div class="col-md-11">
              <b-pagination
                size="md"
                align="center"
                :total-rows="totalRows"
                @change="pageChange"
                v-model="pageNumber"
                :per-page="customerFilters.limit"
              ></b-pagination>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { listCustomer, getCustomer } from "@/api/customer.api.js";
export default {
  name: "Customers",
  data() {
    return {
      visible: false,
      totalRows: 0,
      fields: [
        {
          key: "id",
          label: this.$t("CUSTOMERS.TABLE.1"),
          _classes: "font-weight-bold",
        },
        { key: "given_name", label: this.$t("CUSTOMERS.TABLE.2") },
        { key: "city", label: this.$t("CUSTOMERS.TABLE.3") },
        { key: "country", label: this.$t("CUSTOMERS.TABLE.4") },
        { key: "created_at", label: this.$t("CUSTOMERS.TABLE.5") },
      ],
      customerFilters: {
        skip: 0,
        limit: 10,
        search: "",
      },
      customers: [],
      selected: null,
      pageNumber: 1,
    };
  },
  methods: {
    pageChange(val) {
      this.customerFilters.skip = (val - 1) * this.customerFilters.limit;
      this.getCustomerList();
    },
    getCustomerList() {
      let paramsMap = new Map();
      paramsMap['skip'] = this.customerFilters.skip;
      paramsMap['limit'] = this.customerFilters.limit;
      listCustomer(this.generatePostParams(paramsMap))
        .then((response) => {
          this.customers = response.data.data.customers;
          this.totalRows = response.data.data.count;
        })
        .catch((e) => {
          if (e.response.data.error) this.showToaster(e.response.data.error);
        });
    },
    getCustomerDetailsByID(item, index) {
      getCustomer(item["id"])
        .then((response) => {
          this.selected = response.data.data;
          this.selected["created_at"] = this.formatDate(
            this.selected["created_at"]
          );
          delete this.selected["metadata"];
          delete this.selected["middle_name"];
          delete this.selected["sur_name"];
          delete this.selected["address_line_1"];
          delete this.selected["address_line_2"];
          delete this.selected["updated_at"];
          delete this.selected["region"];
          this.visible = true;
        })
        .catch((e) => {
          if (e.response.data.error) this.showToaster(e.response.data.error);
        });
    },
    getLabelKey(key) {
      switch (key) {
        case "id":
          return this.$t("CUSTOMERS.TABLE.1");
        case "given_name":
          return this.$t("CUSTOMERS.TABLE.2");
        case "city":
          return this.$t("CUSTOMERS.TABLE.3");
        case "country":
          return this.$t("CUSTOMERS.TABLE.4");
        case "customer_ref_id":
          return this.$t("CUSTOMERS.TABLE.6");
        case "email":
          return this.$t("CUSTOMERS.TABLE.8");
        case "mobile":
          return this.$t("CUSTOMERS.TABLE.9");
        case "postal_code":
          return this.$t("CUSTOMERS.TABLE.10");
        case "created_at":
          return this.$t("CUSTOMERS.TABLE.5");
        default:
          return key;
      }
    },
  },
  mounted() {
    this.getCustomerList();
  },
  computed: {
    detailFields() {
      if (this.visible) {
        return [
          { key: "key", label: this.selected.id, _style: "width:150px" },
          { key: "value", label: "", _style: "width:150px;" },
        ];
      }
    },
    visibleData() {
      const customerDetails = this.selected
        ? Object.entries(this.selected)
        : [["id", "Not found"]];
      return customerDetails.map(([key, value]) => {
        return { key, value };
      });
    },
  },
};
</script>
